export const SET_INITIAL = 'SET_INITIAL';
export const SET_STORE_TYPE_OF_PRICES = 'SET_STORE_TYPE_OF_PRICES';
export const ADD_PRICE = 'ADD_PRICE';
export const UPDATE_PRICE = 'UPDATE_PRICE';
export const DELETE_PRICE = 'DELETE_PRICE';
export const PRICES_SUCCESS = 'PRICES_SUCCESS';
export const UPDATE_PROCESS = 'UPDATE_PROCESS';
export const SHOW_ALERT_MESSAGE = 'SHOW_ALERT_MESSAGE';
export const CREATE_ORDER_SET_STEP = 'CREATE_ORDER_SET_STEP';
export const CREATE_ORDER_SET_DELIVERY_OPTIONS = 'CREATE_ORDER_SET_DELIVERY_OPTIONS';
export const CREATE_ORDER_SET_VALUE = 'CREATE_ORDER_SET_VALUE';
export const CREATE_ORDER_SET_BILLING_VALUE = 'CREATE_ORDER_SET_BILLING_VALUE';
export const CREATE_ORDER_SET_SELECTED_VALUE = 'CREATE_ORDER_SET_SELECTED_VALUE';
export const CREATE_ORDER_SET_BILLING_ADDRESS = 'CREATE_ORDER_SET_BILLING_ADDRESS';
export const CREATE_ORDER_SET_SHIPPING = 'CREATE_ORDER_SET_SHIPPING';
export const CREATE_ORDER_SET_STORE_TYPE = 'CREATE_ORDER_SET_STORE_TYPE';
export const CREATE_ORDER_SET_PRODUCTS = 'CREATE_ORDER_SET_PRODUCTS';
export const CREATE_ORDER_DELETE_PRODUCTS = 'CREATE_ORDER_DELETE_PRODUCTS';
export const CREATE_ORDER_SET_COMMENTS = 'CREATE_ORDER_SET_COMMENTS';
export const CREATE_ORDER_SET_STAFF_NOTES = 'CREATE_ORDER_SET_STAFF_NOTES';
export const CREATE_ORDER_SET_LEASE_PROVIDER = 'CREATE_ORDER_SET_LEASE_PROVIDER';
export const CREATE_ORDER_SET_APPLICATION_ID = 'CREATE_ORDER_SET_APPLICATION_ID';
export const CREATE_ORDER_ADD_QTY_PRODUCTS = 'CREATE_ORDER_ADD_QTY_PRODUCTS';