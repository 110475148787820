import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Grid,
  Card,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  colors,
  Button,
  Box, CardHeader, CardContent
} from '@material-ui/core';
import {useParams} from 'react-router-dom';
import {axios_v2, getToastHttpError} from '../../utils';
import {Page} from 'components';
import CardMedia from '@material-ui/core/CardMedia';
import moment from 'moment';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import RefreshIcon from '@material-ui/icons/Refresh';
import {syncBySKU} from '../../utils/resources/product_sync';
import {default as ProductGigaHistory} from '../Products/ProductGigaHistory';
import useForm, {FormContext} from 'react-hook-form';
import {getProductById} from '../../utils/resources/giga_product';
import PopupSyncProduct from './components/PopupSyncProduct';
import PopupSetCustomPrice from './components/PopupSetCustomPrice';


const useStyles = makeStyles((theme) => {
  return ({
    root: {
      paddingTop: 10,
      paddingRight: 1,
      paddingLeft: 10,
      boxSizing: 'border-box'
    },
    results: {
      marginTop: 10,
      marginRight: 10
    },
    container: {},
    tableWrapper: {
      width: '100%'
    },
    table: {
      width: '100%'
    },
    cellImg: {
      width: '100px',
      height: '100px'
    },
    media: {
      marginRight: theme.spacing(1),
      width: '100px',
      height: '100px',
      backgroundSize: 'contain',
    },
    cellValign: {
      verticalAlign: 'top',
      padding: '16px'
    },
    spinnerWrap: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100vw',
      height: '50vh'
    },
    spinnerRoot: {},
    syncButton: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(1),
      height: 37
    },
    spinnerIcon: {
      marginRight: theme.spacing(1),
      marginLeft: 4,
      color: colors.grey[100]
    },
    filterIcon: {
      marginRight: theme.spacing(1)
    },
    spacer: {
      flexGrow: 1
    },
    footerBtn: {
      '&:last-child': {
        marginBottom: 0
      },
      width: '320px'
    },
    tableRow: {
      '&:nth-child(even)': {
        backgroundColor: '#fafafa'
      }
    },
    cellFlex: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    btnSetCustomPrice: {
      backgroundColor: colors.green[600],
      color: '#fff',
      '&:hover': {
        backgroundColor: colors.green[900]
      }
    },
    ulItem: {
      marginLeft: '16px'
    },
    priceWrap: {
      display: 'flex',
      alignItems: 'center'
    },
    label: {
      color: 'rgb(100, 56, 0) !important'
    },
    actual: {
      backgroundColor: colors.yellow[400],
      padding: '2px'
    },
    inline: {
      display: 'flex'
    },
    desc: {
      maxWidth: '750px'
    }
  });
});

export const ProductGigaCard = props => {
  // eslint-disable-next-line no-unused-vars
  // const { rest } = props;
  const classes = useStyles();

  const methods = useForm({
    defaultValues: {
      regularPrice: true,
      ddAvailable: true,
      active: true,
      ddCategory: true,
      discountedPrice: true,
      exclusivePrice: true,
      salePrice: true,
      skuAvailable: true,
      customPrice: true,
      promotionTo: true,
      promotionFrom: true,
    }
  });

  const dispatch = useDispatch();

  const [product, setProduct] = useState({});
  const [isHotList, setIsHotList] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isProgressIn, setIsProgress] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSetCustomPrice, setIsOpenSetCustomPrice] = useState(false);

  const {id} = useParams();

  useEffect(() => {
    getProduct(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const payload = {
      main_title: 'Product',
      secondary_title: 'Furniture Product Card',
      back_arrow: true
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch, product]);

  function getProduct(id) {
    getProductById(id)
      .then(response => {
        console.log(response)
        setProduct(response.data);
        setIsHotList(response.data?.isHotList);
      })
      .catch(response => {
        getToastHttpError(response);
      })
      .finally(() => setIsLoading(false));
  }

  const handleChangeHotList = async () => {
    setIsLoading(true);
    return axios_v2().post('/private/product/update', {
      id: id,
      is_hot: !isHotList,
      store_type: 'furniture',
    }).then(response => {

      if (response?.data) {

        setIsHotList(!isHotList);
        setIsLoading(false);
        if (!isHotList) {
          toast.success('The product was added to the HotList');
        } else {
          toast.success('The product was removed from the HotList');
        }

      } else {
        getToastHttpError('Something Went Wrong');
        setIsLoading(false);
      }

    }).catch(error => {
      getToastHttpError(error || 'Something Went Wrong');
      setIsLoading(false);
    });
  };

  const handleChangeCustomPrice = async (amount) => {
    setIsLoading(true);
    return axios_v2().post('/private/product/update', {
      id: id,
      custom_price: amount,
      store_type: 'furniture',
    }).then(response => {

      if (response?.data) {
        setIsLoading(false)
        toast.success('Product updated!');
        getProduct(id)
      } else {
        getToastHttpError('Something Went Wrong');
        setIsLoading(false);
      }

    }).catch(error => {
      getToastHttpError(error || 'Something Went Wrong');
      setIsLoading(false);
    });
  };

  const syncProduct = () => {
    setIsProgress(true);

    return syncBySKU('giga', `${product.sku}`)
      .then(() => getProduct(id))
      .catch(error => getToastHttpError(error || 'Something Went Wrong'))
      .finally(() => setIsProgress(false));
  };

  const openPopupSyncProduct = () => {
    setIsOpen(true);
  };
  const closePopupSyncProduct = () => {
    setIsOpen(false);
  };

  // const openPopupSetCustomPrice = () => {
  //   setIsOpenSetCustomPrice(true);
  // };
  const closePopupSetCustomPrice = () => {
    setIsOpenSetCustomPrice(false);
  };


  return (
    <Page
      // className={classes.root}
      title="Product Details"
    >
      <Box className={classes.results}>
        {(isLoading) ?
          <Box className={classes.spinnerWrap}>
            <div className={classes.spinnerRoot}>
              <CircularProgress size={60}/>
            </div>
          </Box> :
          <Grid
            className={classes.container}
            container
            spacing={1}
          >
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
            >
              <Card>
                <CardHeader title={product?.name}/>
                <CardContent>
                  <div className={classes.inline}>
                    <CardMedia
                      className={classes.media}
                      image={product?.imageUrls[0] || '/images/no_image_product.png'}
                      name="product-img"
                      title={product?.name || ''}
                    />

                    <Typography
                      name="hotlist"
                      variant="subtitle2"
                    >
                      <b>HotList:</b> <Button onClick={handleChangeHotList}>
                      {isHotList ? <img src='/images/hot-active.svg' alt={'Hot Active'}/> :
                        <img src='/images/hot.svg' alt={'Not Hot Active'}/>}
                    </Button>
                    </Typography>

                  </div>
                  {product?.description &&
                    <>
                      <Typography
                        name="product-long-description"
                        variant="h6"
                      >
                        <b>Description:</b> <br/>
                      </Typography>

                      <div className={classes.desc}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: product.description
                          }}
                        />
                      </div>
                    </>
                  }

                  <Typography
                    name="product-long-description"
                    variant="h6"
                  >
                    Giga category:
                  </Typography>
                  <Typography variant="subtitle2">
                    <li className={classes.ulItem}>{product?.category}</li>
                  </Typography>

                  {!!product?.categoryPathGiga &&
                    <>
                      <Typography
                        name="product-long-description"
                        variant="h6"
                      >
                        Giga categories path:
                      </Typography>
                      <Typography variant="subtitle2">
                        <li className={classes.ulItem}>{product?.categoryPathGiga}</li>
                      </Typography>
                    </>
                  }

                  <Typography
                    name="product-long-description"
                    variant="h6"
                  >
                    BuyOnTrust category:
                  </Typography>
                  <Typography variant="subtitle2">
                    <li className={classes.ulItem}>{product?.ddCategory}</li>
                  </Typography>

                  {!!product?.categoryPath &&
                    <>
                      <Typography
                        name="product-long-description"
                        variant="h6"
                      >
                        BuyOnTrust categories path:
                      </Typography>
                      <Typography variant="subtitle2">
                        <li className={classes.ulItem}>{product?.categoryPath?.map(item => item.name)?.join('>>')}</li>
                      </Typography>
                    </>
                  }

                  <Grid
                    container
                    spacing={1}>
                    {/*<Grid*/}
                    {/*  item>*/}
                    {/*  <Button*/}
                    {/*    className={clsx([classes.btnSetCustomPrice, classes.footerBtn])}*/}
                    {/*    onClick={openPopupSetCustomPrice}*/}
                    {/*    variant="contained"*/}
                    {/*  >*/}
                    {/*    <AttachMoney size={20} />*/}
                    {/*    Set custom price*/}
                    {/*  </Button>*/}
                    {/*</Grid>*/}
                    <Grid
                      item>
                      <Button
                        color="primary"
                        onClick={openPopupSyncProduct}
                        variant="contained"
                        className={classes.footerBtn}
                      >
                        {(isProgressIn) ?
                          <>
                            <CircularProgress
                              className={classes.spinnerIcon}
                              size={20}
                            /> Synchronization
                          </> :
                          <><RefreshIcon className={classes.filterIcon}/> Synchronization</>
                        }
                      </Button>
                    </Grid>
                  </Grid>

                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              lg={4}
              sm={6}
              xl={4}
              xs={12}
            >
              <Card>
                <CardHeader title="Information"/>
                <CardContent>
                  <Table>
                    <TableBody>

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            SKU:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">{product?.sku}</Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            Cost of goods:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            {isNaN(parseFloat(product?.costPrice)) ? ("N/A")
                              : (`$${parseFloat(product?.costPrice).toFixed(2)}`
                              )}</Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            Retail price:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            {isNaN(parseFloat(product?.retailPriceWithShippingFee)) ? ("N/A") : (
                              `$${parseFloat(product?.retailPriceWithShippingFee).toFixed(2)}`
                            )} </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            Calculated price (Auto):
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {isNaN(parseFloat(product?.calculatedPriceWithShippingFee)) ? (
                            <Typography variant="subtitle2">
                              "N/A"
                            </Typography>
                          ) : (
                            <Typography variant="subtitle2">
                              ${parseFloat(product?.calculatedPriceWithShippingFee)?.toFixed(2)}
                              {/*{(product?.calculatedPriceWithShippingFee >= product?.customPrice) &&*/}
                              {/*<CheckCircleIcon style={{color: colors.green[600]}}/>*/}
                              {/*}*/}
                            </Typography>
                          )}

                          {/*{(product?.calculatedPrice >= product?.customPrice) && <>*/}
                          {/*  <Label*/}
                          {/*    color={colors.orange[100]}*/}
                          {/*    className={classes.label}*/}
                          {/*  >*/}
                          {/*    Sale price is more than Custom price*/}
                          {/*  </Label>*/}
                          {/*</>*/}
                          {/*}*/}
                        </TableCell>
                      </TableRow>
                      {/*}*/}

                      {/*{(product?.customPrice || product?.customPrice === 0) &&*/}
                      {/*  <TableRow>*/}
                      {/*    <TableCell>*/}
                      {/*      <Typography variant="h6">*/}
                      {/*        Custom price:*/}
                      {/*      </Typography>*/}
                      {/*    </TableCell>*/}
                      {/*    <TableCell>*/}
                      {/*      <Typography variant="subtitle2">${parseFloat(product?.customPrice)?.toFixed(2)} {(product?.calculatedPrice < product?.customPrice) && <CheckCircleIcon style={{ color: colors.green[600] }} />}</Typography>*/}
                      {/*    </TableCell>*/}
                      {/*  </TableRow>*/}
                      {/*}*/}

                      {/*<TableRow>*/}
                      {/*  <TableCell>*/}
                      {/*    <Typography variant="h6">*/}
                      {/*      Regular price (GIGA):*/}
                      {/*    </Typography>*/}
                      {/*  </TableCell>*/}
                      {/*  <TableCell>*/}
                      {/*    <Typography variant="subtitle2">*/}
                      {/*      {isNaN(parseFloat(product?.regularPrice)) ? ("N/A") : (*/}
                      {/*        `$${parseFloat(product?.regularPrice).toFixed(2)}`*/}
                      {/*      )} </Typography>*/}
                      {/*  </TableCell>*/}
                      {/*</TableRow>*/}

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            Exclusive price:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            {isNaN(parseFloat(product?.exclusivePrice)) ? ("N/A") : (
                              `$${parseFloat(product?.exclusivePrice + (product?.shippingFee || 0)).toFixed(2)}`
                            )}</Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            Discounted price:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            {isNaN(parseFloat(product?.discountedPrice)) ? (
                              "N/A"
                            ) : (`$${parseFloat(product?.discountedPrice + (product?.shippingFee || 0)).toFixed(2)}`)}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            Promotion from:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            {!product?.promotionFrom ? "N/A" : (
                              `${moment(product.promotionFrom).format('DD MMM YYYY h:mm a')}`)}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            Promotion to:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            {!product?.promotionTo ? "N/A" : (
                              `${moment(product.promotionTo).format('DD MMM YYYY h:mm a')}`)}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            Available (DD):
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">{product?.ddAvailable ?
                            <CheckCircleIcon style={{color: colors.green[600]}}/> :
                            <HighlightOffIcon
                              className={classes.ico}
                              style={{color: colors.red[600]}}
                            />
                          }</Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            To be published (GIGA):
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">{product?.toBePublished ?
                            <CheckCircleIcon style={{color: colors.green[600]}}/> :
                            <HighlightOffIcon
                              className={classes.ico}
                              style={{color: colors.red[600]}}
                            />
                          }</Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            SKU Available (GIGA):
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">{product?.skuAvailable ?
                            <CheckCircleIcon style={{color: colors.green[600]}}/> :
                            <HighlightOffIcon
                              className={classes.ico}
                              style={{color: colors.red[600]}}
                            />
                          }</Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            Quantity (GIGA):
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2"><span>{parseInt(product?.quantity)}</span></Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            Not exist category (DD):
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">{!product?.isNotExistCategoryDD ?
                            <CheckCircleIcon style={{color: colors.green[600]}}/> :
                            <HighlightOffIcon
                              className={classes.ico}
                              style={{color: colors.red[600]}}
                            />
                          }</Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            Created at:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            {product?.createdAt ? moment(product.createdAt).format('DD MMM YYYY h:mm a') : ''}</Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            Updated at:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            {product?.updatedAt ? moment(product.updatedAt).format('DD MMM YYYY h:mm a') : ''}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            Source:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            {product?.source}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            Rating:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            {product?.customerReviewAverage || 0}
                          </Typography>
                        </TableCell>
                      </TableRow>


                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              lg={4}
              sm={6}
              xl={4}
              xs={12}
            >
              <Card>
                <CardHeader title="Details"/>
                <CardContent>
                  <Table>
                    <TableBody>
                      {(product?.attributes) &&
                        product.attributes.map((item, index) => (
                          <TableRow key={index}>
                            {Object.entries(item).map(([key, value]) => (
                              key !== '_id' &&
                              <React.Fragment key={key}>
                                <TableCell>
                                  <Typography variant="h6">
                                    {key}:
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="subtitle2">
                                    {value}
                                  </Typography>
                                </TableCell>
                              </React.Fragment>
                            ))}
                          </TableRow>
                        ))}
                      {product.weight &&
                        <TableRow>
                          <React.Fragment>
                            <TableCell>
                              <Typography variant="h6">
                                Weight :
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="subtitle2">
                                {product.weight}
                              </Typography>
                            </TableCell>
                          </React.Fragment>
                        </TableRow>}
                      {product.weightKg &&
                        <TableRow>
                          <React.Fragment>
                            <TableCell>
                              <Typography variant="h6">
                                Weight KG :
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="subtitle2">
                                {product.weightKg}
                              </Typography>
                            </TableCell>
                          </React.Fragment>
                        </TableRow>}
                      {product['length'] &&
                        <TableRow>
                          <React.Fragment>
                            <TableCell>
                              <Typography variant="h6">
                                Length :
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="subtitle2">
                                {product['length']}
                              </Typography>
                            </TableCell>
                          </React.Fragment>
                        </TableRow>}
                      {product['lengthCm'] &&
                        <TableRow>
                          <React.Fragment>
                            <TableCell>
                              <Typography variant="h6">
                                Length CM:
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="subtitle2">
                                {product['lengthCm']}
                              </Typography>
                            </TableCell>
                          </React.Fragment>
                        </TableRow>}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              lg={4}
              sm={6}
              xl={4}
              xs={12}
            >
              <FormContext {...methods} >
                <ProductGigaHistory
                  productId={id}
                />
              </FormContext>
            </Grid>
          </Grid>
        }
      </Box>
      <PopupSyncProduct isOpen={isOpen} closePopupSyncProduct={closePopupSyncProduct} syncProduct={syncProduct}/>
      <PopupSetCustomPrice
        isOpen={isOpenSetCustomPrice}
        closePopupSyncProduct={closePopupSetCustomPrice}
        syncProduct={syncProduct}
        salePrice={product?.calculatedPrice}
        customPrice={product?.customPrice}
        changeCustomPrise={handleChangeCustomPrice}
      />
    </Page>
  );
};

ProductGigaCard.propTypes = {
  rest: PropTypes.any
};
