import { colors } from '@material-ui/core';
import theme from '../theme';

export const PATHS = {
  ADMIN_SETTINGS: '/admin_settings',
  INVENTORY_SETTINGS: '/inventory_settings',
  LEASE_PROVIDERS: '/lease_providers',
  MESSAGING_SETTINGS: '/message_settings/:channel_type',
  MESSAGING_SETTINGS_SMS: '/message_settings/sms',
  MESSAGING_SETTINGS_EMAIL: '/message_settings/email',
  PRICE_SETTINGS: '/price_management',
  MARKETING_TREE: '/marketing/tree_oid',
  MARKETING_TREE_OID: '/marketing/tree_oid2',
  MARKETING_TREE_PID: '/marketing/tree_pid',
  MARKETING_TREE_SID: '/marketing/tree_sid',
  MARKETING_TREE_EID: '/marketing/tree_eid',
};

export const EMPLOYEE_TYPES = ['Person', 'Service'];
export const RISK_FILTER_TYPE = ['Approval', 'Cancellation'];
// export const POSSIBLE_ROLES = ['admin', 'manager', 'salaries manager'];

//serviceTypes
export const SERVICE_TYPES = [
  'acima',
  'bigcommerce',
  'westcreek',
  'aff',
  'flexshopper',
  'paytomorrow',
  'uown'
];

export const CHECKOUT_HISTORY_SERVICES = [
  {
    label: 'PreCheckout',
    value: 'precheckout'
  },
  {
    label: 'Acima',
    value: 'acima'
  },
  {
    label: 'BigCommerce',
    value: 'bigcommerce'
  },
  {
    label: 'Koalafi',
    value: 'westcreek'
  },
  {
    label: 'American First Finance',
    value: 'aff'
  },
  {
    label: 'UOwn',
    value: 'uown'
  }
];

// --- lease providers
export const LEASE_PROVIDERS_TYPE = [
  {
    label: 'Acima',
    value: 'acima',
    color: colors.blue[600],
    color_dashboard: theme.palette.primary.dark,
    fields: [
      'application_status',
      'created',
      'approval_amount',
      'application_id',
      'checkout_token',
      'lease_id',
      'lease_number',
    ],
    can_default_lease_provider: true,
    store_type: 'electronics'
  },
  {
    label: 'Koalafi',
    value: 'westcreek',
    color: colors.teal[600],
    color_dashboard: theme.palette.info.main,
    fields: [
      'application_status',
      'created',
      'approval_amount',
      'lease_id',
    ],
    can_default_lease_provider: true,
    store_type: 'electronics'
  },
  {
    label: 'American First Finance',
    value: 'aff',
    color: colors.cyan[600],
    color_dashboard: colors.cyan[600],
    fields: [
      'application_status',
      'created',
      'approval_amount',
      'application_id',
      'checkout_token',
      'lease_id',
      'lease_number'
    ],
    can_default_lease_provider: true,
    store_type: 'electronics'
  },
  {
    label: 'FlexShopper',
    value: 'flexshopper',
    color: colors.green[600],
    color_dashboard: colors.green[600],
    fields: [
      'application_status',
      'created',
      'approval_amount',
      'application_id',
      'transaction_id',
      'lease_number',
    ],
    can_default_lease_provider: false,
    store_type: 'electronics'
  },
  {
    label: 'PayTomorrow',
    value: 'paytomorrow',
    color: colors.deepPurple[600],
    color_dashboard: colors.deepPurple[600],
    fields: [
      'application_status',
      'created',
      'approval_amount',
      'application_id',
      // 'transaction_id',
      // 'lease_number',
      'application_token',
      'sub_provider',
    ],
    can_default_lease_provider: true
  },
  {
    label: 'UOwn (electronics)',
    value: 'uown',
    color: colors.blue[300],
    color_dashboard: colors.blue[300],
    fields: [
      'application_status',
      'created',
      'approval_amount',
      'application_id',
      'transaction_id',
      'lease_number',
      'application_token',
      'sub_provider',
      'is_added_amount',
      'original_amount',
    ],
    can_default_lease_provider: true,
    store_type: 'electronics'
  },
  {
    label: 'UOwn (furniture)',
    value: 'uown_furniture',
    color: colors.cyan[600],
    color_dashboard: colors.cyan[600],
    fields: [
      'application_status',
      'created',
      'approval_amount',
      'application_id',
      'transaction_id',
      'lease_number',
      'application_token',
      'sub_provider',
      'is_added_amount',
      'original_amount',
    ],
    can_default_lease_provider: true,
    store_type: 'furniture'
  },
  {
    label: 'BOT',
    value: 'bot',
    color: colors.deepPurple[300],
    color_dashboard: colors.deepPurple[300],
    fields: [
      'application_status',
      'approval_amount',
      'application_id',
    ],
    can_default_lease_provider: false,
    store_type: 'electronics'
  },
];

export const SALES_FUNNEL_STEPS = [
  {
    label: 'Step 1: Phone validated',
    value: '1'
  },
  {
    label: 'Step 2: Code entered',
    value: '2'
  },
  {
    label: 'Step 3: Basic info entered',
    value: '3'
  },
  {
    label: 'Step 4: Address entered',
    value: '4'
  },
  {
    label: 'Step 5: Address validated',
    value: '5'
  },
  {
    label: 'Step 6: Employment info entered',
    value: '6'
  },
  {
    label: 'Step 7: Summary submitted',
    value: '7'
  },
  {
    label: 'Step 8: Approve received',
    value: '8'
  },
  {
    label: 'Step 9: Decline received',
    value: '9'
  },
  {
    label: 'Step 10: Checkout modal',
    value: '10'
  },
  {
    label: 'Step 11: Sale',
    value: '11'
  }
]

export const SALES_FUNNEL_LOSSES = [
  // {
  //   label: 'Losses before Step 1: Phone validated',
  //   value: '1'
  // },
  {
    label: 'Losses before Step 2: Code entered',
    value: '2'
  },
  {
    label: 'Losses before Step 3: Basic info entered',
    value: '3'
  },
  {
    label: 'Losses before Step 4: Address entered',
    value: '4'
  },
  {
    label: 'Losses before Step 5: Address validated',
    value: '5'
  },
  {
    label: 'Losses before Step 6: Employment info entered',
    value: '6'
  },
  {
    label: 'Step 7: Summary submitted',
    value: '7'
  },
  {
    label: 'Step 8: Approve received',
    value: '8'
  },
  {
    label: 'Losses before Step 9: Decline received',
    value: '9'
  },
  {
    label: 'Losses before Step 10: Checkout modal',
    value: '10'
  },
  {
    label: 'Losses before Step 11: Sale',
    value: '11'
  }
]

export const DEFAULT_LEASE_PROVIDER = {
  label: 'Undefined',
  value: 'undefined',
  color: colors.grey[600]
};

export const LEASE_PAYMENT_STATUS = [
  {
    label: 'Unreconciled',
    value: 'unreconciled',
    color: colors.yellow[600]
  },
  {
    label: 'Reconciled',
    value: 'reconciled',
    color: colors.green[600]
  },
  {
    label: 'Incorrect payment',
    value: 'incorrect',
    color: colors.red[600]
  },
];

// --- Categories
// export const SENDGRID_CATEGORIES= [
//   'Approved',
//   'Not Approved',
//   'Approvals no Order',
//   'Approvals no Order (Historical)',
//   'Ordered',
//   'Ready For Pickup',
//   'Abandoned'
// ];

export const CATEGORY_COLORS = {
  'not_approved': colors.grey[600],
  'approved': colors.blue[700],
  'ordered': colors.blue[900],
  'ready_for_pickup': colors.green[800],
  'approvals_no_order': colors.pink[600],
  'approvals_no_order_historical': colors.pink[900],
  'abandoned': colors.grey[600],
  'default': colors.grey[300],
  'prequalified_no_order': colors.pink[300],
  'approvals_no_order_furniture': colors.pink[400],
};

// --- Application_status by lease providers (from Approval API request)
export const APPLICATION_STATUSES = [
  {
    label: 'Approved',
    value: 'approved',
  },
  {
    label: 'Not Approved',
    value: 'not_approved',
  },
  {
    label: 'Abandoned',
    value: 'abandoned',
  },
  {
    label: 'Sms Test',
    value: 'sms_test',
  },
];

// ---
export const PAYMENT_STATUS_COLORS = {
  'Pending': colors.deepPurple[600],
  'Cancelled': colors.grey[600],
  'Placed With BestBuy': colors.blue[600],
  'Placed With Giga': colors.blue[600],
  'Being Processed': colors.deepPurple[300],
  'Ready For Pickup': colors.green[600],
  'Fulfillment Confirmed': colors.lightBlue[600],
  'Lease Initiated': colors.indigo[600],
  'Lease Confirmed': colors.teal[600],
  'Manual Verification Required': colors.red[600],
  'Waiting For Approve': colors.lime[600],
  'Approved': colors.green[600],
  'Not approved': colors.red[600],
};

export const RISK_FILTER_APPLYING_MODES = [
  {
    label: 'Approval',
    value: 'approval',
  },
  {
    label: 'Cancellation',
    value: 'cancellation',
  },
];


export const DISPOSITION_LIST = [
  {
    label: 'Voicemail',
    value: 'Voicemail',
  },
  {
    label: 'Asked for a later call',
    value: 'Asked for a later call',
  },
  {
    label: 'Insufficient approval amount',
    value: 'Insufficient approval amount',
  },
  {
    label: 'Expensive',
    value: 'Expensive',
  },
  {
    label: 'Too much sensitive information required',
    value: 'Too much sensitive information required',
  },
  {
    label: 'Changed my mind',
    value: 'Changed my mind',
  },
  {
    label: 'Not interested',
    value: 'Not interested',
  },
  {
    label: 'Placed an order',
    value: 'Placed an order',
  },
  {
    label: 'Unaware of approval/BOT',
    value: 'Unaware of approval/BOT',
  },
  {
    label: 'Invalid number',
    value: 'Invalid number',
  },
  {
    label: 'Plans to order',
    value: 'Plans to order',
  },
  {
    label: 'Control group',
    value: 'Control group',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const FILTER_BY_TIME_OF = [
  'Approval', // default should be FILTER_BY_TIME_OF[0]
  'Call',
];

export const STORE_TYPE_OPTIONS = [
  {
    value: 'electronics',
    label: 'Electronics'
  },
  {
    value: 'furniture',
    label: 'Furniture'
  },
];

export const LENDERS = [
  {
    value: 'bot',
    label: 'BOT'
  },
  {
    value: 'uown',
    label: 'UOWN electronics'
  },
  {
    value: 'uown_furniture',
    label: 'UOWN furniture'
  }
]

