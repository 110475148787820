import { makeStyles } from '@material-ui/styles';
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
    inputQty: {
        width: '50px',
        height: '50px',
        textAlign: 'center',
        borderRadius: 0,
        border: 'none',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        outline: 'none',
        '&::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },

        '-moz-appearance': 'textfield',
    },
    btn : {
        height: '50px',
        width: '30px',
        borderRadius: 0,
        outline: 'none',
        border:'1px solid rgba(224, 224, 224, 1)',
        fontSize: '20px',
        background: 'transparent',
        cursor: 'pointer'
    },
    container : {
        display: 'flex',
        alignItems: 'center'
    }
}));
  

export const Qty = ({ min = 1, max = Infinity,sku, value = 1 }) => {
  const [count, setCount] = useState(value);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    value !== count && setCount(value);
    // eslint-disable-next-line
  }, [value]);

  const handleChangeQtyProduct = useCallback((quantity) => {
    dispatch({
      type: 'CREATE_ORDER_ADD_QTY_PRODUCTS',
      payload: {quantity,sku},
    });
    // eslint-disable-next-line
  }, [dispatch]);

  function increase() {
    const newCount = Math.min(max, count + 1);
    setCount(newCount);
  }

  function decrease() {
    const newCount = Math.max(min, count - 1);
    setCount(newCount);
  }

  function changeCount(e) {
    let value = e.target.value ? parseInt(e.target.value) : min;
    const newCount = value < min ? min : value > max ? max : value;
    setCount(newCount);
  }

  useEffect(() => {
    handleChangeQtyProduct(count)
    // eslint-disable-next-line
  },[count])

  return (
    <div className="">
      <div className={classes.container}>
        <span className="">
          <button
            className={classes.btn}
            onClick={decrease}
            type="button"
            disabled={count <= min}>-</button>
        </span>
        <input
          className={classes.inputQty}
          type="number"
          min="1"
          max={max}
          value={count}
          onChange={changeCount}
        />
        <span className="">
          <button
            className={classes.btn}
            onClick={increase}
            type="button"
            disabled={count >= max}>+</button>
        </span>
      </div>
    </div>
  );
}
